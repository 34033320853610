import { default as abuseReportsUhFtNdsHYGMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93YOE030QjwSMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/admin/auditLog/[username]/[id].vue?macro=true";
import { default as indexJjog00siNnMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/admin/index.vue?macro=true";
import { default as moderationPHHKA1LAxHMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/admin/moderation.vue?macro=true";
import { default as pendingBansbrTBsKyU8TMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/admin/pendingBans.vue?macro=true";
import { default as profilesM4EmD10jyzMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/admin/profiles.vue?macro=true";
import { default as indexVAVSOYtvaqMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/admin/timesheets/index.vue?macro=true";
import { default as overview8dIzKszzpaMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingvHfdJxCgzCMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingno2eVjnaMEMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/admin/translations/missing.vue?macro=true";
import { default as usersBH8Byt1ygPMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/admin/users.vue?macro=true";
import { default as apiEvo0HrA0CJMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/api.vue?macro=true";
import { default as _91slug_93HbKykVc5opMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/blog/[slug].vue?macro=true";
import { default as indexsKVK8Edp0LMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_9315XsZLgd1CMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93AjtFk8ZxuwMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminMsg6oMHuIRMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/census/admin.vue?macro=true";
import { default as indexIkZIulujNcMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/census/index.vue?macro=true";
import { default as contactZWuME3wBTbMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/contact.vue?macro=true";
import { default as designRbrEt25s7XMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/design.vue?macro=true";
import { default as englishbiHNOXof1BMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/english.vue?macro=true";
import { default as faqBlqSHTs7mpMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/faq.vue?macro=true";
import { default as inclusiveTSDfoSsT81Meta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/inclusive.vue?macro=true";
import { default as indexzvOeI3SFKqMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/index.vue?macro=true";
import { default as licenseOSOo8uBzqCMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/license.vue?macro=true";
import { default as academic8BTvmg9WabMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/links/academic.vue?macro=true";
import { default as index7KEjN0T2dpMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/links/index.vue?macro=true";
import { default as media0zvCiR7Fy0Meta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/links/media.vue?macro=true";
import { default as translinguisticsCDyaGIdTg5Meta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/links/translinguistics.vue?macro=true";
import { default as zineqyP1FYrH5yMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/links/zine.vue?macro=true";
import { default as namese4ipVv7CEdMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/names.vue?macro=true";
import { default as indexKUWLNTVtgqMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/nouns/index.vue?macro=true";
import { default as templatesUQuRhNRYSIMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/nouns/templates.vue?macro=true";
import { default as peopleq1mg3Dbm86Meta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/people.vue?macro=true";
import { default as privacyMjY4p2Qu3EMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/privacy.vue?macro=true";
import { default as _91username_93LcSaN1GpjyMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93nGwYEkK2eqMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/profile/card-[username].vue?macro=true";
import { default as editor7VxeePf00HMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/profile/editor.vue?macro=true";
import { default as anyqhjpZLeFS3Meta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/pronouns/any.vue?macro=true";
import { default as avoidingQGmL7JHxijMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/pronouns/avoiding.vue?macro=true";
import { default as index0T6ma1qPQDMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/pronouns/index.vue?macro=true";
import { default as mirror07qnhoaPhaMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/pronouns/mirror.vue?macro=true";
import { default as pronoun8kxwfWIUheMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesSjQ45iL84QMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/sources.vue?macro=true";
import { default as team3t46qFcWseMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/team.vue?macro=true";
import { default as terminologyT5e4jsx3uYMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/terminology.vue?macro=true";
import { default as terms9EJbgWWF81Meta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/terms.vue?macro=true";
import { default as userf2gzkoWC7UMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/user.vue?macro=true";
import { default as workshopsrfwLtzAiugMeta } from "/home/admin/www/pronouns.page/release/20240916195631/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsUhFtNdsHYGMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/admin/abuseReports.vue").then(m => m.default || m)
  },
  {
    name: "admin-auditLog-username-id",
    path: "/admin/auditLog/:username()/:id()",
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/admin/auditLog/[username]/[id].vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/admin/moderation.vue").then(m => m.default || m)
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansbrTBsKyU8TMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/admin/pendingBans.vue").then(m => m.default || m)
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/admin/profiles.vue").then(m => m.default || m)
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/admin/timesheets/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/admin/timesheets/overview.vue").then(m => m.default || m)
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/admin/translations/awaiting.vue").then(m => m.default || m)
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/admin/translations/missing.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "api",
    path: "/api",
    meta: apiEvo0HrA0CJMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/api.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93HbKykVc5opMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexsKVK8Edp0LMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_9315XsZLgd1CMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/calendar/[[year]].vue").then(m => m.default || m)
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93AjtFk8ZxuwMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/calendar/[year]-[month]-[day].vue").then(m => m.default || m)
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminMsg6oMHuIRMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/census/admin.vue").then(m => m.default || m)
  },
  {
    name: "census",
    path: "/census",
    meta: indexIkZIulujNcMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/census/index.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactZWuME3wBTbMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/design.vue").then(m => m.default || m)
  },
  {
    name: "english",
    path: "/english",
    meta: englishbiHNOXof1BMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/english.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqBlqSHTs7mpMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveTSDfoSsT81Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/inclusive.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/license.vue").then(m => m.default || m)
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academic8BTvmg9WabMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/links/academic.vue").then(m => m.default || m)
  },
  {
    name: "links",
    path: "/links",
    meta: index7KEjN0T2dpMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/links/index.vue").then(m => m.default || m)
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: media0zvCiR7Fy0Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/links/media.vue").then(m => m.default || m)
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsCDyaGIdTg5Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/links/translinguistics.vue").then(m => m.default || m)
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineqyP1FYrH5yMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/links/zine.vue").then(m => m.default || m)
  },
  {
    name: "names",
    path: "/names",
    meta: namese4ipVv7CEdMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/names.vue").then(m => m.default || m)
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexKUWLNTVtgqMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/nouns/index.vue").then(m => m.default || m)
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesUQuRhNRYSIMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/nouns/templates.vue").then(m => m.default || m)
  },
  {
    name: "people",
    path: "/people",
    meta: peopleq1mg3Dbm86Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/people.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyMjY4p2Qu3EMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93LcSaN1GpjyMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/profile/[username].vue").then(m => m.default || m)
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93nGwYEkK2eqMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/profile/card-[username].vue").then(m => m.default || m)
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editor7VxeePf00HMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/profile/editor.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyqhjpZLeFS3Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/pronouns/any.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingQGmL7JHxijMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/pronouns/avoiding.vue").then(m => m.default || m)
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: index0T6ma1qPQDMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/pronouns/index.vue").then(m => m.default || m)
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirror07qnhoaPhaMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/pronouns/mirror.vue").then(m => m.default || m)
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronoun8kxwfWIUheMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/pronouns/pronoun.vue").then(m => m.default || m)
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesSjQ45iL84QMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/sources.vue").then(m => m.default || m)
  },
  {
    name: "team",
    path: "/team",
    meta: team3t46qFcWseMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/team.vue").then(m => m.default || m)
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyT5e4jsx3uYMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/terminology.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: terms9EJbgWWF81Meta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    meta: userf2gzkoWC7UMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/user.vue").then(m => m.default || m)
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsrfwLtzAiugMeta || {},
    component: () => import("/home/admin/www/pronouns.page/release/20240916195631/pages/workshops.vue").then(m => m.default || m)
  }
]