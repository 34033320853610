import revive_payload_client_g4p80gUmFU from "/home/admin/www/pronouns.page/release/20240916195631/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.11.5_eslint@8.57.0_rollup@4.21.0_sass@1.32.12_typescript@5.5.2_vite@5.4.3_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3LKlL5wtUN from "/home/admin/www/pronouns.page/release/20240916195631/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.11.5_eslint@8.57.0_rollup@4.21.0_sass@1.32.12_typescript@5.5.2_vite@5.4.3_vue-tsc@2.1.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_giClwmIrgD from "/home/admin/www/pronouns.page/release/20240916195631/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.11.5_eslint@8.57.0_rollup@4.21.0_sass@1.32.12_typescript@5.5.2_vite@5.4.3_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_L3q0x4wTdI from "/home/admin/www/pronouns.page/release/20240916195631/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.11.5_eslint@8.57.0_rollup@4.21.0_sass@1.32.12_typescript@5.5.2_vite@5.4.3_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_2J0DwGd5w4 from "/home/admin/www/pronouns.page/release/20240916195631/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.11.5_eslint@8.57.0_rollup@4.21.0_sass@1.32.12_typescript@5.5.2_vite@5.4.3_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_hZ3L8P43nW from "/home/admin/www/pronouns.page/release/20240916195631/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.11.5_eslint@8.57.0_rollup@4.21.0_sass@1.32.12_typescript@5.5.2_vite@5.4.3_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_o1T8SRHO9v from "/home/admin/www/pronouns.page/release/20240916195631/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.11.5_eslint@8.57.0_rollup@4.21.0_sass@1.32.12_typescript@5.5.2_vite@5.4.3_vue-tsc@2.1.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_kvnZUnvECT from "/home/admin/www/pronouns.page/release/20240916195631/node_modules/.pnpm/@pinia+nuxt@0.5.4_rollup@4.21.0_typescript@5.5.2_vue@3.5.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/admin/www/pronouns.page/release/20240916195631/.nuxt/components.plugin.mjs";
import prefetch_client_HLqkJdDQLn from "/home/admin/www/pronouns.page/release/20240916195631/node_modules/.pnpm/nuxt@3.13.1_@types+node@20.11.5_eslint@8.57.0_rollup@4.21.0_sass@1.32.12_typescript@5.5.2_vite@5.4.3_vue-tsc@2.1.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/home/admin/www/pronouns.page/release/20240916195631/.nuxt/pwa-icons-plugin.ts";
import pwa_client_LqhRzU4n8L from "/home/admin/www/pronouns.page/release/20240916195631/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_rollup@4.21.0_vite@5.4.3_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_client_W8oHVhRBIh from "/home/admin/www/pronouns.page/release/20240916195631/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_rollup@4.21.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import auth_vT9JWWT9pN from "/home/admin/www/pronouns.page/release/20240916195631/plugins/auth.ts";
import browserDetect_3Y4e6ym8wL from "/home/admin/www/pronouns.page/release/20240916195631/plugins/browserDetect.ts";
import globals_qEnHRCYwqu from "/home/admin/www/pronouns.page/release/20240916195631/plugins/globals.ts";
import polyfill_client_aOiFD9Uk19 from "/home/admin/www/pronouns.page/release/20240916195631/plugins/polyfill.client.ts";
import sentry_client_shVUlIjFLk from "/home/admin/www/pronouns.page/release/20240916195631/plugins/sentry.client.ts";
import track_client_qrg1Mffzr3 from "/home/admin/www/pronouns.page/release/20240916195631/plugins/track.client.ts";
import plugin_auto_pageviews_client_ONDGdkkEZZ from "/home/admin/www/pronouns.page/release/20240916195631/node_modules/.pnpm/@nuxtjs+plausible@1.0.2_rollup@4.21.0/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_g4p80gUmFU,
  unhead_3LKlL5wtUN,
  router_giClwmIrgD,
  payload_client_L3q0x4wTdI,
  navigation_repaint_client_2J0DwGd5w4,
  check_outdated_build_client_hZ3L8P43nW,
  chunk_reload_client_o1T8SRHO9v,
  plugin_vue3_kvnZUnvECT,
  components_plugin_KR1HBZs4kY,
  prefetch_client_HLqkJdDQLn,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_LqhRzU4n8L,
  plugin_client_W8oHVhRBIh,
  auth_vT9JWWT9pN,
  browserDetect_3Y4e6ym8wL,
  globals_qEnHRCYwqu,
  polyfill_client_aOiFD9Uk19,
  sentry_client_shVUlIjFLk,
  track_client_qrg1Mffzr3,
  plugin_auto_pageviews_client_ONDGdkkEZZ
]